import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { Typography, Box } from '@mui/material'

import { Recurring, api } from '@shared/api/src'
import { valueToNumber } from '@shared/api/src/utils/valueToNumber'
import { Loading, SurchargeDisclosure } from '@shared/components'
import AppTheme from '@shared/design'
import { useEnforceLogin } from '@shared/hooks'
import { recurringTypes, accountTypeLabel } from '@shared/types'
import {
  currency,
  formatDate,
  formatDatetime,
  calculateSurcharge,
} from '@shared/utils'

interface PrintRecurringSummaryProps {
  recurring?: Recurring
  surcharge?: string | number
  isCompliantSurchargeApplied?: boolean
  productTransaction?: Recurring['product_transaction']
}

const useStyles = tss.withName('PrintRecurringSummary').create(({ theme }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100vh',
    margin: '0',
  },

  transactionField: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: '500px',
    boxSizing: 'border-box',
  },

  divider: {
    width: '500px',
    height: '1px',
    background: theme.palette.divider,
    margin: theme.spacing(1, 0),
    color: 'inherit',
  },

  textLabel: {
    fontSize: '14px',
  },

  textValue: {
    fontSize: '14px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  receiptLogo: {
    maxWidth: '9.375rem',
    maxHeight: '3.125rem',
    marginRight: theme.spacing(2),
    display: 'block',
  },
}))

const TransactionHeader: React.FC<PrintRecurringSummaryProps> = ({
  productTransaction,
}) => {
  const { classes } = useStyles(AppTheme)
  return productTransaction?.receipt_logo ||
    productTransaction?.receipt_header ? (
    <>
      <Box className={classes.transactionField}>
        {productTransaction?.receipt_logo && (
          <img
            alt="receipt logo"
            className={classes.receiptLogo}
            src={productTransaction.receipt_logo}
          />
        )}

        {productTransaction?.receipt_header && (
          <div
            style={{ paddingRight: '50%' }}
            dangerouslySetInnerHTML={{
              __html: productTransaction.receipt_header,
            }}
          />
        )}
      </Box>
    </>
  ) : (
    <></>
  )
}

const TotalsSection: React.FC<PrintRecurringSummaryProps> = ({
  recurring,
  surcharge,
}) => {
  const { classes } = useStyles(AppTheme)
  const { t } = useTranslation()

  return (
    <>
      <Box className={classes.transactionField}>
        <Typography component="span" className={classes.textLabel}>
          {t('common.recurring-billing.amount-per-payment')}
        </Typography>
        <Typography component="span" className={classes.textValue}>
          {currency(
            recurring?.transaction_amount / 100 + valueToNumber(surcharge)
          )}
        </Typography>
      </Box>

      {recurring?.recurring_type_id === 'i' && (
        <>
          <Box className={classes.transactionField}>
            <Typography className={classes.textLabel}>
              {t('common.recurring-billing.number-of-payments')}
            </Typography>
            <Typography className={classes.textValue}>
              {recurring?.installment_total_count}
            </Typography>
          </Box>
          <Box className={classes.transactionField}>
            <Typography className={classes.textLabel}>
              {t('common.recurring-billing.total-to-collect')}
            </Typography>
            <Typography className={classes.textValue}>
              {currency(
                (recurring?.installment_amount_total ?? 0) / 100 +
                  valueToNumber(surcharge) *
                    (recurring?.installment_total_count ?? 0)
              )}
            </Typography>
          </Box>
        </>
      )}
    </>
  )
}

const SettingsSection: React.FC<PrintRecurringSummaryProps> = ({
  recurring,
}) => {
  const { classes } = useStyles(AppTheme)
  const { t } = useTranslation()

  const interval =
    recurring?.interval_type === 'm'
      ? t('common.month-s')
      : recurring?.interval_type === 'w'
      ? t('common.week-s')
      : t('common.day-s')

  return (
    <>
      <Box className={classes.transactionField}>
        <Typography component="span" className={classes.textLabel}>
          {t('common.recurring-billing.type')}
        </Typography>
        <Typography component="span" className={classes.textValue}>
          {recurringTypes[recurring.recurring_type_id]}
        </Typography>
      </Box>
      <Box className={classes.transactionField}>
        <Typography component="span" className={classes.textLabel}>
          {t('common.recurring-billing.frequency')}
        </Typography>
        <Typography component="span" className={classes.textValue}>
          {recurring?.interval ? recurring?.interval + ' ' + interval : '-'}
        </Typography>
      </Box>
      <Box className={classes.transactionField}>
        <Typography component="span" className={classes.textLabel}>
          {t('common.date-start')}
        </Typography>
        <Typography component="span" className={classes.textValue}>
          {formatDate(recurring?.start_date, 'UTC') || '-'}
        </Typography>
      </Box>
      <Box className={classes.transactionField}>
        <Typography component="span" className={classes.textLabel}>
          {t('common.date-end')}
        </Typography>
        <Typography component="span" className={classes.textValue}>
          {formatDate(recurring?.end_date, 'UTC') || '-'}
        </Typography>
      </Box>
      {recurring?.next_run_date !== '0000-00-00' && (
        <Box className={classes.transactionField}>
          <Typography component="span" className={classes.textLabel}>
            {t('common.date-next-run')}
          </Typography>
          <Typography component="span" className={classes.textValue}>
            {formatDate(recurring?.next_run_date, 'UTC') || '-'}
          </Typography>
        </Box>
      )}
    </>
  )
}

const DetailsSection: React.FC<PrintRecurringSummaryProps> = ({
  recurring,
}) => {
  const { classes } = useStyles(AppTheme)
  const { t } = useTranslation()
  return (
    <>
      <Box className={classes.transactionField}>
        <Typography component="span" className={classes.textLabel}>
          {`${t('common.account-type')} / ${t('common.token-last-four')}`}
        </Typography>
        <Typography component="span" className={classes.textValue}>
          {accountTypeLabel[recurring?.account_vault?.account_type]} •{' '}
          {recurring?.account_vault?.last_four}
        </Typography>
      </Box>
      <Box className={classes.transactionField}>
        <Typography component="span" className={classes.textLabel}>
          {t('common.description')}
        </Typography>
        <Typography component="span" className={classes.textValue}>
          {recurring?.description}
        </Typography>
      </Box>
    </>
  )
}

const DateSection: React.FC<PrintRecurringSummaryProps> = ({ recurring }) => {
  const { classes } = useStyles(AppTheme)
  const { t } = useTranslation()
  const { user } = useEnforceLogin()

  return (
    <>
      <Box className={classes.transactionField}>
        <Typography component="span" className={classes.textLabel}>
          {`${t('common.date')} / ${t('common.time')}`}
        </Typography>
        <Typography component="span" className={classes.textValue}>
          {formatDatetime(recurring.created_ts, user.tz)}
        </Typography>
      </Box>
      <Box className={classes.transactionField}>
        <Typography component="span" className={classes.textLabel}>
          {t('common.recurring-billing.id')}
        </Typography>
        <Typography component="span" className={classes.textValue}>
          {recurring.id}
        </Typography>
      </Box>
    </>
  )
}

const FooterSection: React.FC<PrintRecurringSummaryProps> = ({
  recurring,
  isCompliantSurchargeApplied,
}) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { user } = useEnforceLogin()

  const productTransaction = recurring?.product_transaction

  return (
    <div style={{ width: 'min-content' }}>
      {isCompliantSurchargeApplied && (
        <SurchargeDisclosure
          merchantAccount={productTransaction}
          containerStyle={{
            marginBottom: '16px',
          }}
        />
      )}

      {productTransaction?.receipt_add_recurring_above_signature && (
        <Typography component="p" className={classes.textLabel}>
          {productTransaction?.receipt_add_recurring_above_signature}
        </Typography>
      )}

      {recurring?.signature?.signature ? (
        <img
          className={classes.receiptLogo}
          style={{ marginTop: '56px' }}
          src={`data:image/bmp;base64,${recurring.signature.signature}`}
          alt={t('common.signature')}
        />
      ) : (
        <>
          <hr
            className={classes.divider}
            style={{ marginTop: '56px', marginBottom: '4px' }}
          />
          <Typography
            component="p"
            className={`${classes.textLabel} ${classes.textValue}`}
            style={{ fontWeight: 'medium' }}
          >
            {t('common.signature')}
          </Typography>
        </>
      )}

      {recurring?.payment_schedule?.length > 0 && (
        <Box display="flex" flexDirection="column" width="100%" mt={4}>
          <Typography variant="subtitle1" mb={2}>
            {t('common.payment-schedule-original')}
          </Typography>

          <Box
            justifyContent="space-between"
            alignItems="center"
            mb={2}
            style={{ maxHeight: '160px', overflowY: 'auto' }}
          >
            {recurring.payment_schedule
              .reduce((result, value, index, array) => {
                if (index % 2 === 0) {
                  const pair = [value, array[index + 1]].filter(Boolean)
                  result.push(pair)
                }
                return result
              }, [])
              .map((pair, index) => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '5px 0',
                  }}
                  key={index}
                >
                  {pair.map((date, i) => (
                    <Typography key={i} variant="subtitle1" noWrap>
                      {date ? formatDate(new Date(date)) : ''}
                    </Typography>
                  ))}
                </div>
              ))}
          </Box>
        </Box>
      )}

      {productTransaction?.receipt_footer && (
        <div
          className={classes.transactionField}
          style={{ marginTop: '16px' }}
          dangerouslySetInnerHTML={{
            __html: productTransaction.receipt_footer,
          }}
        />
      )}
    </div>
  )
}

const UnboundPrintRecurringSummary: React.FC<PrintRecurringSummaryProps> = ({
  recurring,
  surcharge,
  isCompliantSurchargeApplied,
}) => {
  const { classes } = useStyles(AppTheme)
  const { t } = useTranslation()
  return (
    <>
      <Box className={classes.root}>
        <TransactionHeader productTransaction={recurring.product_transaction} />
        <hr className={classes.divider}></hr>
        <TotalsSection recurring={recurring} surcharge={surcharge} />
        <hr className={classes.divider}></hr>
        <SettingsSection recurring={recurring} />
        <hr className={classes.divider}></hr>
        <DetailsSection recurring={recurring} />
        <hr className={classes.divider}></hr>
        <DateSection recurring={recurring} />
        <hr className={classes.divider}></hr>
        <FooterSection
          recurring={recurring}
          isCompliantSurchargeApplied={isCompliantSurchargeApplied}
        />
      </Box>
    </>
  )
}

export const PrintRecurringSummary = () => {
  const { recurringId } = useParams()

  const [recurring, setRecurring] = useState<Recurring>()

  const [surcharge, setSurcharge] = useState<number>(0)
  const [isCompliantSurchargeApplied, setIsCompliantSurchargeApplied] =
    useState(false)

  useEffect(() => {
    setTimeout(
      () =>
        api
          .service('recurring-billings')
          .get(recurringId ?? '')
          .then(setRecurring)
          .catch((error) => {
            alert(error.message)
          }),
      1000
    )
  }, [])

  useEffect(() => {
    const fetchSurchargeAndPrint = async () => {
      if (
        !recurring.account_vault ||
        (recurring.product_transaction?.surcharge?.compliant &&
          recurring.product_transaction?.surcharge?.state_exception_check &&
          !recurring.account_vault.billing_address.postal_code)
      ) {
        setSurcharge(0)
        return
      }

      try {
        const amounts = await calculateSurcharge(
          recurring.product_transaction,
          valueToNumber(recurring.transaction_amount / 100),
          0, // tax - will not apply with recurring
          0, // tip - will not apply with recurring
          recurring.account_vault.id
        )

        setIsCompliantSurchargeApplied(
          amounts?.surcharge_applied &&
            !!recurring.product_transaction?.surcharge?.compliant
        )
        setSurcharge(amounts?.surcharge_amount / 100)
      } catch (error) {
        console.error('Error calculating surcharge:', error)
        setIsCompliantSurchargeApplied(false)
      } finally {
        setTimeout(() => window.print(), 1000)
      }
    }

    if (recurring) {
      fetchSurchargeAndPrint()
    }
  }, [recurring])

  return recurring ? (
    <UnboundPrintRecurringSummary
      recurring={recurring}
      surcharge={surcharge}
      isCompliantSurchargeApplied={isCompliantSurchargeApplied}
    />
  ) : (
    <Loading />
  )
}
